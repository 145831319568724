import { default as indexn5MddCbZMJMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/[...slug]/index.vue?macro=true";
import { default as _91uuid_932LDlXWlujpMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/blokkli/library-item/[uuid].vue?macro=true";
import { default as indexK1TuVYpu4fMeta } from "/builds/oris/oris-web/frontend/layers/cart/pages/[prefix]/cart/index.vue?macro=true";
import { default as indexDiXWiGUr8uMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/index.vue?macro=true";
import { default as indexr4AreSGS5EMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/loveitem-finder/index.vue?macro=true";
import { default as indexhm8HQI5U6SMeta } from "/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/magazine-article/index.vue?macro=true";
import { default as indexNNNQ4Y9kwZMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/product/[...fallback]/index.vue?macro=true";
import { default as _91sku_93bshgXUWHbVMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/product/[type]/[name]/[sku].vue?macro=true";
import { default as _91sku_93RgQK9YJRgpMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/product/strap/[name]/[sku].vue?macro=true";
import { default as _91sku_937cCgF5tojBMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/product/watch/[collection]/[watchName]/[sku].vue?macro=true";
import { default as indexFsZlmlt5QoMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/product/watch/[collection]/index.vue?macro=true";
import { default as indexdlkKGTYxr7Meta } from "/builds/oris/oris-web/frontend/pages/[prefix]/storefinder/index.vue?macro=true";
import { default as indexB5O50fOPSRMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/strap-finder/index.vue?macro=true";
import { default as indexXQGbA0DNFdMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/buttons/index.vue?macro=true";
import { default as indexD576oKOtVIMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/colors/index.vue?macro=true";
import { default as home_45stage7nVFVGauuqMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/home-stage.vue?macro=true";
import { default as indexC1gsKGDlSsMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/index.vue?macro=true";
import { default as Checkbox9L2I9SzJi1Meta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/Checkbox.vue?macro=true";
import { default as DropdownxmKD40RxvbMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/Dropdown.vue?macro=true";
import { default as index27JiZdfGGCMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/index.vue?macro=true";
import { default as RadioSU2pIlMSA4Meta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/Radio.vue?macro=true";
import { default as RadioGroupacjVgXC1hwMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/RadioGroup.vue?macro=true";
import { default as TextInputca1tCJ4hsFMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/TextInput.vue?macro=true";
import { default as indexPySZhIQj6EMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/link/index.vue?macro=true";
import { default as indexbUNEEKnpDpMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/media-image/index.vue?macro=true";
import { default as gallery_45gridg1XNne4LFEMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/gallery-grid.vue?macro=true";
import { default as text_45imageZG8vXAddKZMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/text-image.vue?macro=true";
import { default as textH53P0aT2plMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/text.vue?macro=true";
import { default as video2V1FrV6DVMMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/video.vue?macro=true";
import { default as indexe5W09qHtT0Meta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/slider/index.vue?macro=true";
import { default as indexmaXwIPsEThMeta } from "/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/variations/index.vue?macro=true";
import { default as indexD3TJ81vYK2Meta } from "/builds/oris/oris-web/frontend/pages/[prefix]/watch-finder/index.vue?macro=true";
import { default as indexG1JPmbO6nhMeta } from "/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/worldoforis/[category]/index.vue?macro=true";
import { default as indexh12vBiuC4NMeta } from "/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/worldoforis/index.vue?macro=true";
import { default as worldoforiswzdYVANh7QMeta } from "/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/worldoforis.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:prefix()/:slug(.*)*",
    meta: indexn5MddCbZMJMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/[...slug]/index.vue")
  },
  {
    name: "prefix-blokkli-library-item-uuid",
    path: "/:prefix()/blokkli/library-item/:uuid()",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/blokkli/library-item/[uuid].vue")
  },
  {
    name: "cart",
    path: "/:prefix()/cart",
    meta: indexK1TuVYpu4fMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/layers/cart/pages/[prefix]/cart/index.vue")
  },
  {
    name: "home",
    path: "/:prefix()",
    meta: indexDiXWiGUr8uMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/index.vue")
  },
  {
    name: "loveitem-finder",
    path: "/:prefix()/loveitem-finder",
    meta: indexr4AreSGS5EMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/loveitem-finder/index.vue")
  },
  {
    name: "worldoforis-article",
    path: "/:prefix/worldoforis/:category/:slug(.*)*",
    meta: indexhm8HQI5U6SMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/magazine-article/index.vue")
  },
  {
    name: "prefix-product-fallback",
    path: "/:prefix()/product/:fallback(.*)*",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/product/[...fallback]/index.vue")
  },
  {
    name: "product-detail-generic",
    path: "/:prefix/product/:type(gift|giveaway|tool|spare-part)/:name/:sku",
    meta: _91sku_93bshgXUWHbVMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/product/[type]/[name]/[sku].vue")
  },
  {
    name: "product-detail-strap",
    path: "/:prefix()/product/strap/:name()/:sku()",
    meta: _91sku_93RgQK9YJRgpMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/product/strap/[name]/[sku].vue")
  },
  {
    name: "product-detail-watch",
    path: "/:prefix()/product/watch/:collection()/:watchName()/:sku()",
    meta: _91sku_937cCgF5tojBMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/product/watch/[collection]/[watchName]/[sku].vue")
  },
  {
    name: "prefix-product-watch-collection",
    path: "/:prefix()/product/watch/:collection()",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/product/watch/[collection]/index.vue")
  },
  {
    name: "storefinder",
    path: "/:prefix/storefinder/:id?",
    meta: indexdlkKGTYxr7Meta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/storefinder/index.vue")
  },
  {
    name: "strap-finder",
    path: "/:prefix()/strap-finder",
    meta: indexB5O50fOPSRMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/strap-finder/index.vue")
  },
  {
    name: "prefix-styleguide-buttons",
    path: "/:prefix()/styleguide/buttons",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/buttons/index.vue")
  },
  {
    name: "prefix-styleguide-colors",
    path: "/:prefix()/styleguide/colors",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/colors/index.vue")
  },
  {
    name: "prefix-styleguide-home-stage",
    path: "/:prefix()/styleguide/home-stage",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/home-stage.vue")
  },
  {
    name: "prefix-styleguide",
    path: "/:prefix()/styleguide",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/index.vue")
  },
  {
    name: "prefix-styleguide-inputs-Checkbox",
    path: "/:prefix()/styleguide/inputs/Checkbox",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/Checkbox.vue")
  },
  {
    name: "prefix-styleguide-inputs-Dropdown",
    path: "/:prefix()/styleguide/inputs/Dropdown",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/Dropdown.vue")
  },
  {
    name: "prefix-styleguide-inputs",
    path: "/:prefix()/styleguide/inputs",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/index.vue")
  },
  {
    name: "prefix-styleguide-inputs-Radio",
    path: "/:prefix()/styleguide/inputs/Radio",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/Radio.vue")
  },
  {
    name: "prefix-styleguide-inputs-RadioGroup",
    path: "/:prefix()/styleguide/inputs/RadioGroup",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/RadioGroup.vue")
  },
  {
    name: "prefix-styleguide-inputs-TextInput",
    path: "/:prefix()/styleguide/inputs/TextInput",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/inputs/TextInput.vue")
  },
  {
    name: "prefix-styleguide-link",
    path: "/:prefix()/styleguide/link",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/link/index.vue")
  },
  {
    name: "prefix-styleguide-media-image",
    path: "/:prefix()/styleguide/media-image",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/media-image/index.vue")
  },
  {
    name: "prefix-styleguide-paragraph-gallery-grid",
    path: "/:prefix()/styleguide/paragraph/gallery-grid",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/gallery-grid.vue")
  },
  {
    name: "prefix-styleguide-paragraph-text-image",
    path: "/:prefix()/styleguide/paragraph/text-image",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/text-image.vue")
  },
  {
    name: "prefix-styleguide-paragraph-text",
    path: "/:prefix()/styleguide/paragraph/text",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/text.vue")
  },
  {
    name: "prefix-styleguide-paragraph-video",
    path: "/:prefix()/styleguide/paragraph/video",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/paragraph/video.vue")
  },
  {
    name: "prefix-styleguide-slider",
    path: "/:prefix()/styleguide/slider",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/slider/index.vue")
  },
  {
    name: "prefix-styleguide-variations",
    path: "/:prefix()/styleguide/variations",
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/styleguide/variations/index.vue")
  },
  {
    name: "watch-finder",
    path: "/:prefix()/watch-finder",
    meta: indexD3TJ81vYK2Meta || {},
    component: () => import("/builds/oris/oris-web/frontend/pages/[prefix]/watch-finder/index.vue")
  },
  {
    name: worldoforiswzdYVANh7QMeta?.name,
    path: "/:prefix()/worldoforis",
    component: () => import("/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/worldoforis.vue"),
    children: [
  {
    name: "prefix-worldoforis-category",
    path: ":category()",
    component: () => import("/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/worldoforis/[category]/index.vue")
  },
  {
    name: "worldoforis",
    path: "",
    meta: indexh12vBiuC4NMeta || {},
    component: () => import("/builds/oris/oris-web/frontend/layers/world-of-oris/pages/[prefix]/worldoforis/index.vue")
  }
]
  }
]